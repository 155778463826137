<template>
  <div>
    <calendarTitle v-if="data.type !== 'servicepicker'" :data="data" :callback="function(ret) { callback(ret) }" :settings="settings" />
    <div v-else >
      <h3 class="flix-html-h3">{{ $t('message.serviceTitle') }}</h3>
      <div class="flix-form-group">
        <div class="flix-text-info">
          <flixIcon :id="'info-sign'" /> <span v-html="$t('message.serviceDescription')" />
        </div>
      </div>
      <loginForm :saveCalendar="function(){}"/>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    calendarTitle () { return import('@/components/assistent/components/calendarTitle') },
    loginForm () { return import('@/components/assistent/components/assets/login') }
  },
  props: {
    data: Object,
    callback: Function,
    settings: Object
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
